// src/pages/HomePage.js
import React from 'react';
import Services from '../components/Services';
import NosMissions from '../components/NosMissions';
import QuiSommesNous from '../components/QuiSommesNous';
import './HomePage.css'; // Importer le fichier CSS
import Header from '../components/Header';

const HomePage = () => {
  return (
    
    <div className="home-page">
      {/* <h1>Bienvenue sur BCKonnectel</h1>
      <p>Votre partenaire pour la transformation numérique.</p> */}
      <Header />
      <Services />
      <NosMissions />
      <QuiSommesNous />
    </div>
  );
};

export default HomePage;

