// src/components/Services.js
import React from 'react';
import './Services.css';

const Services = () => {
  return (
    <div className="services fullscreen">
      <h2>Nos Services</h2>
      <ul>
        <li>Création de sites web</li>
        <li>Marketing digital</li>
        <li>Création d'applications mobiles</li>
        <li>Conseil en stratégie digitale</li>
      </ul>
    </div>
  );
};

export default Services;
