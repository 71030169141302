// src/components/Header.js
import React from 'react';
import './Header.css';

const Header = () => {
  return (
    <header className="header text-white fullscreen">
      <div className="header-content">
        <h1>Bienvenue dans Mon Application</h1>
        <p>La créativité au service de votre succès!</p>
      </div>
    </header>
  );
};

export default Header;
