// src/pages/MobileAppCreationPage.js
import React from 'react';
import './MobileAppCreationPage.css'; // Importer le fichier CSS

const MobileAppCreationPage = () => {
  return (
    <div className="mobile-app-creation fullscreen">
      <h1>Création d'Applications Mobiles</h1>
      <p>Nous développons des applications mobiles sur mesure pour votre entreprise.</p>
    </div>
  );
};

export default MobileAppCreationPage;
