// src/components/QuiSommesNous.js
import React from 'react';
import './QuiSommesNous.css'

const QuiSommesNous = () => {
  return (
    <div className="qui-sommes-nous">
      <h2>Qui Sommes-Nous</h2>
      <p>
        Nous sommes une équipe de professionnels passionnés par la technologie et l'innovation. 
        Notre objectif est d'accompagner les entreprises dans leur transformation numérique et 
        de leur fournir des outils adaptés à leurs besoins.
      </p>
    </div>
  );
};

export default QuiSommesNous;
