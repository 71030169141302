// src/pages/ContactezNous.js
import React, { useState } from 'react';
import './ContactezNous.css';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const [errors, setErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name.trim()) {
      newErrors.name = 'Le nom est requis';
    }
    if (!formData.email.trim()) {
      newErrors.email = 'L\'email est requis';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'L\'email n\'est pas valide';
    }
    if (!formData.message.trim()) {
      newErrors.message = 'Le message est requis';
    }
    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formErrors = validateForm();
    setErrors(formErrors);

    if (Object.keys(formErrors).length === 0) {
      console.log('Form data submitted:', formData);
      setSubmitted(true);
    }
  };

  return (
    <div className="contact-form">
      <div className="container">
        <h2>Contactez-nous</h2>
        <div>Prêt à faire passer votre entreprise au niveau supérieur ? Contactez-nous dès aujourd'hui pour discuter de vos besoins en marketing digital et en création de sites web.</div>
        {submitted ? (
          <div className="alert alert-success text-center">
            Merci pour votre message !
          </div>
        ) : (
          <form onSubmit={handleSubmit}>
            <div>
              <label htmlFor="name">Nom</label>
              <input
                type="text"
                id="name"
                name="name"
                className={`form-control ${errors.name && 'is-invalid'}`}
                value={formData.name}
                onChange={handleInputChange}
              />
              {errors.name && <div className="invalid-feedback">{errors.name}</div>}
            </div>
            <div>
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                className={`form-control ${errors.email && 'is-invalid'}`}
                value={formData.email}
                onChange={handleInputChange}
              />
              {errors.email && <div className="invalid-feedback">{errors.email}</div>}
            </div>
            <div>
              <label htmlFor="message">Votre message</label>
              <textarea
                id="message"
                name="message"
                className={`form-control ${errors.message && 'is-invalid'}`}
                rows="5"
                value={formData.message}
                onChange={handleInputChange}
              />
              {errors.message && <div className="invalid-feedback">{errors.message}</div>}
            </div>
            <button type="submit">Envoyer</button>
          </form>
        )}
      </div>
    </div>
  );
};

export default ContactForm;
