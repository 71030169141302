// src/components/Footer.js
import React from 'react';
import './Footer.css'; // Si tu as un fichier CSS personnalisé

const Footer = () => {
  return (
    <footer className="footer text-light py-4">
      <div className="container-fluid">
        <div className="row">
          {/* Section Contact */}
          <div className="col-12 col-md-4 text-center mb-3">
            <p>Rejoignez-nous sur</p>
            <a href="mailto:contact@bckonnectel.com">
              contact@bckonnectel.com
            </a>
          </div>

          {/* Section Réseaux Sociaux */}
          <div className="col-12 col-md-4 text-center mb-3">
            <ul className="list-inline">
              <li className="list-inline-item">
                <a href="https://www.facebook.com/profile.php?id=61554931500507&mibextid=LQQJ4d" target="_blank" rel="noopener noreferrer" >
                  <i className="bi bi-facebook"></i>
                </a>
              </li>
              <li className="list-inline-item">
                <a href="https://www.instagram.com/bckonnectel?igsh=MXd0Y3owd2xxNmh5NQ%3D%3D&utm_source=qr" target="_blank" rel="noopener noreferrer">
                  <i className="bi bi-instagram"></i>
                </a>
              </li>
              <li className="list-inline-item">
                <a href="https://www.linkedin.com/company/onlinemarkting/" target="_blank" rel="noopener noreferrer">
                  <i className="bi bi-linkedin"></i>
                </a>
              </li>
              <li className="list-inline-item">
                <a href="https://wa.me/+221777619867" target="_blank" rel="noopener noreferrer">
                  <i className="bi bi-whatsapp"></i>
                </a>
              </li>
            </ul>
          </div>

          {/* Section Copyright */}
          <div className="col-12 col-md-4 text-center">
            <p>© {new Date().getFullYear()} BC Konnectel &middot; Tous droits réservés.</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
