// src/pages/NotFoundPage.js
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './NotFoundPage.css'; // Optionnel, pour le style

const NotFoundPage = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div className="container">
      <div className="content">
        <div className="text-column">
          <h1 className="heading">404 - Page Not Found</h1>
          <p className="message">Désolé, la page que vous recherchez n'existe pas.</p>
        </div>
        <div className="button-column">
          <div className="button-container">
            <button className="back-button" onClick={handleGoBack}>
              Retourner à la page précédente
            </button>
            <Link to="/" className="home-link">Retourner à la page d'accueil</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
