// src/pages/DigitalMarketingPage.js
import React from 'react';
import './DigitalMarketingPage.css'; // Importer le fichier CSS

const DigitalMarketingPage = () => {
  return (
    <div className="digital-marketing fullscreen">
      <h1>Marketing Digital</h1>
      <p>Augmentez votre visibilité en ligne avec nos services de marketing digital.</p>
      <p>Nous utilisons des stratégies de SEO et de médias sociaux pour atteindre votre public cible.</p>
    </div>
  );
};

export default DigitalMarketingPage;

// Répéter pour MobileAppCreationPage.js et WebCreationPage.js avec leur classe CSS respective
