import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import WebCreationPage from './pages/WebCreationPage';
import DigitalMarketingPage from './pages/DigitalMarketingPage';
import MobileAppCreationPage from './pages/MobileAppCreationPage';
import Navbar from './components/Navbar';
import NotFoundPage from './pages/NotFoundPage';
import Footer from './components/Footer';
import ContactezNous from './pages/ContactezNous';
import Loader from './components/Loader'; // Importation du Loader
import './App.css';

const App = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false); // Arrête le chargement après 2 secondes
    }, 2000);
    return () => clearTimeout(timer); // Nettoyage du timer
  }, []);

  return (
    <Router>
      <div>
        {loading ? ( 
          <Loader />
        ) : (
          <>
            <Navbar />
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/web-creation" element={<WebCreationPage />} />
              <Route path="/digital-marketing" element={<DigitalMarketingPage />} />
              <Route path="/mobile-app-creation" element={<MobileAppCreationPage />} />
              <Route path="/contactez-nous" element={<ContactezNous />} />
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
            <Footer />
          </>
        )}
      </div>
    </Router>
  );
};

export default App;
