// src/components/NosMissions.js
import React from 'react';
import './NosMissions.css';

const NosMissions = () => {
  return (
    <div className="nos-missions fullscreen">
      <h2>Nos Missions</h2>
      <p>
        Notre mission est de fournir des solutions numériques innovantes qui aident les entreprises à
        se développer et à atteindre leurs objectifs. Nous croyons en l'importance de l'éducation et
        de l'accompagnement pour maximiser le potentiel de nos clients.
      </p>
    </div>
  );
};

export default NosMissions;
