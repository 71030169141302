// src/pages/WebCreationPage.js
import React from 'react';
import './WebCreationPage.css';

const WebCreationPage = () => {
  return (
    <div className="web-creation fullscreen">
      <h1>Création de Sites Web</h1>
      <p>Nous créons des sites web modernes et réactifs adaptés à vos besoins.</p>
      <p>Contactez-nous pour plus d'informations.</p>
    </div>
  );
};

export default WebCreationPage;
