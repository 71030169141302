// src/components/Navbar.js
import React from 'react';
import { Link } from 'react-router-dom';
import logo from "../images/logo-bckonnectel.png";

const Navbar = () => {
  return (
    <nav className="navbar navbar-expand-lg navbar-light px-5 bg-light fixed-top">
      <div className="container-fluid">
        <a className="navbar-brand" href="/">
          <img src={logo} alt="BC Konnectel" style={{ width: '50px', height: 'auto' }} />
        </a>
        <button 
          className="navbar-toggler" 
          type="button" 
          data-bs-toggle="collapse" 
          data-bs-target="#navbarNav" 
          aria-controls="navbarNav" 
          aria-expanded="false" 
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link className="nav-link" to="/">Accueil</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/web-creation">Création de Sites Web</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/digital-marketing">Marketing Digital</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/mobile-app-creation">Création d'Applications Mobiles</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/contactez-nous">Contactez-nous</Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
